import React, { Component } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
  styled,
} from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { UserService } from "../../service/user.service";
import { BalanceService } from "../../service/balance.service";

import { ConverMoeny, ConvertDate } from "../../utility/help";
import { AnyARecord } from "dns";

const CustomTableCell = styled(TableCell)({
  color: 'white',
  padding:' 4px'
});

const NewExpansionPanel = styled(ExpansionPanel)({
  borderBottom: "1px solid #9E9E9E",
});

const NewExpansionPanelSummary = styled(ExpansionPanelSummary)({
  border: 0,
  borderRadius: 3,
  color: "white",
  padding: "none",
});

interface Props {
  handleClose: () => any;
}

interface State {
  mode: string;
  point: number;

  pass1: string;
  pass2: string;

  balances: any;
  notices: any;

  maxCount: number;
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  }
});


function NoteRow(props: { row: any, DoReadNote : (id : string)=> any  }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  let ref = "";
  if (row.text != null) {
    ref = row.text;
  }

  const onOpenClick = ()=>{
    props.DoReadNote(row._id)
    setOpen(!open)
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root} key={row._id}>
        <CustomTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell>
        <CustomTableCell align="center" onClick={() => {onOpenClick()}}>
          {row.title}
        </CustomTableCell>
        <CustomTableCell align="center">
          {ConvertDate(row.regDate)}
        </CustomTableCell>
        <CustomTableCell align="center">
          {/* {HelpStatus(row.status)} */}
        </CustomTableCell>
      </TableRow>
      <TableRow>
        <CustomTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div dangerouslySetInnerHTML={{ __html: row.text }}></div>
            </Box>

            {/* {RenderRef()} */}
          </Collapse>
        </CustomTableCell>
      </TableRow>
    </React.Fragment>
  );
}


export class User extends Component<Props, State> {
  userService = new UserService();
  balanceService = new BalanceService();

  constructor(props: Props) {
    super(props);

    this.state = {
      mode: "note",

      point: 0,
      pass1: "",
      pass2: "",
      balances: [],
      maxCount: 0,
      notices: [],
    };
  }

  componentDidMount(){
    this.handleNotice()
  }

  handleExchangeToPassword = () => {
    if (this.state.pass1.length < 3 || this.state.pass1.length > 20) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호 입력 또는 3자리 이상 20자리 이하로 작성해주세요",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    if (this.state.pass1 !== this.state.pass2) {
      confirmAlert({
        title: "회원정보",
        message: "비밀번호정보가 동일해야합니다 ",
        buttons: [
          {
            label: "확인",
            onClick: () => {},
          },
        ],
      });
      return;
    }

    this.userService.user_exchange_to_pass(this.state.pass1).then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원정보",
          message: "비밀번호정보가 정상적으로 변경되었습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원정보",
          message:
            "알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }
    });
  };

  handleDoRead = (id: any) => {
    this.userService.do_read_note(id).then((s: any) => {});
  };
  
  handleGetUserRolling = () => {
    this.userService.user_get_user_point().then((s: any) => {
      if (s.status === "success") {
        this.setState({ point: s.point });
      }
    });
  };

  handleBalance = (skip: number) => {
    this.balanceService.get_deposit_and_withdraw(skip).then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balances: data.balances,
          maxCount: data.maxCount === 0 ? 1 : data.maxCount,
        });
      }
    });
  };

  handleNotice = () => {
    this.userService.get_user_note_list().then((data: any) => {
      console.log(data);
      if (data.status === "success") {
        this.setState({ notices: data.note });
      }
    });
  };

  handleExchangeToPoint = () => {
    this.userService.user_chang_to_point().then((s: any) => {
      if (s.status === "success") {
        confirmAlert({
          title: "회원정보",
          message: "롤링포인트가 정상적으로 변경되었습니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (s.status === "low_point") {
        confirmAlert({
          title: "회원정보",
          message: "1000포인트 이상부터 사용가능합니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "회원정보",
          message:
            "알수없는 예러가 발생되습니다 지속적인 문제가 발생되면 문의 부탁드림니다. ",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      }
    });
  };

  render() {
    const RenderExchangePass = () => {
      if (this.state.mode !== "exchange") {
        return <></>;
      }

      return (
        <>
          <div className="con_box10">
            <div className="info_wrap">
              <div className="info2">로그인 비밀번호를 변경합니다.</div>
            </div>
          </div>
          <div className="con_box10">
            <table className="write_title_top">
              <tr>
                <td className="write_title">변경 비밀번호</td>
                <td className="write_basic">
                  <input
                    className="input1"
                    value={this.state.pass1}
                    onChange={(e: any) => {
                      this.setState({ pass1: e.target.value });
                    }}
                  />{" "}
                </td>
              </tr>
              <tr>
                <td className="write_title">확인 비밀번호</td>
                <td className="write_basic">
                  <input
                    className="input1"
                    value={this.state.pass2}
                    onChange={(e: any) => {
                      this.setState({ pass2: e.target.value });
                    }}
                  />{" "}
                </td>
              </tr>
            </table>
          </div>

          <div className="con_box10">
            <div className="btn_wrap_center">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      this.handleExchangeToPassword();
                    }}
                  >
                    <span className="btn3_1">비밀번호 변경</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </>
      );
    };
    const RenderRolling = () => {
      if (this.state.mode !== "rolling") {
        return;
      }

      return (
        <div className="con_box10">
          <div className="info_wrap2">
            <div className="info11">
              현재 롤링 포인트
              <span className="font05">{ConverMoeny(this.state.point)}</span> 원
            </div>
            {/* <div className="info21">
              고객님의 등급 : 일반 (롤링 <span className="font06">0.02</span> %)
              현재 롤링 포인트 : <span className="font06">0</span> 원
            </div> */}
            <div className="con_box10">
              <div className="btn_wrap_center">
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        this.handleExchangeToPoint();
                      }}
                    >
                      <span className="btn3_1">변환하기</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    };

    const RenderBalance = () => {
      if (this.state.mode !== "balance") {
        return;
      }

      return (
        <div className="con_box10">
          <table className="write_title_top">
            <tbody>
              <tr>
                <td className="list_title1">날짜</td>
                <td className="list_title1">타입</td>
                <td className="list_title1">금액</td>
                <td className="list_title1">상태</td>
              </tr>
              {this.state.balances.map((s: any) => {
                return (
                  <tr>
                    <td className="list1">{ConvertDate(s.regdate)}</td>

                    <td className="list1">
                      {s.type === "deposit" ? "충전" : "환전"}
                    </td>
                    <td className="list1">
                      <span className="font05">{ConverMoeny(s.balance)}</span>원
                    </td>
                    <td className="list1">
                      {s.status === "already"
                        ? "완료"
                        : s.status === "cansel"
                        ? "취소"
                        : "신청"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    };

    const RenderNote = () => {
      if (this.state.mode !== "note") {
        return;
      }

      return (
        <div className="con_box10">

           <TableContainer component={Paper}>
            <Table
              size="small"
              aria-label="a dense table"
              style={{ backgroundColor: "#484848" }}
            >
              <TableHead>
                <TableRow>
                  <CustomTableCell align="center"></CustomTableCell>
                  <CustomTableCell align="center">제 목</CustomTableCell>
                  <CustomTableCell align="center">작성일</CustomTableCell>
                  <CustomTableCell align="center">상태</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.notices.map((row: any) => {
                  return <NoteRow  key={row._id} row={row} DoReadNote={()=>{
                    this.handleDoRead(row._id)
                  }}/>
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <table className="write_title_top">
            <tbody>
              <tr>
                <td className="list_title1">날짜</td>
                <td className="list_title1">제목</td>
                <td className="list_title1">상태</td>
                <td className="list_title1">작성일시</td>
              </tr>
              {this.state.notices.map((data: any) => {
                return (
                  <NewExpansionPanel>
                    <NewExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography>
                        <div className="m_list_notice">
                          {data.title}
                          <span className="m_list2">
                            관리자 | {ConvertDate(data.regDate)}
                            <img src="/mo/images/icon_notice.png" />
                          </span>
                        </div>
                      </Typography>
                    </NewExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography>
                        <div
                          dangerouslySetInnerHTML={{ __html: data.contents }}
                        ></div>
                      </Typography>
                    </ExpansionPanelDetails>
                  </NewExpansionPanel>
                );
              })}
          
            </tbody>
          </table> */}
        </div>
      );
    };
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99999,
          background: "none",
          border: "none",
          width: "none",
        }}
        onClose={() => {
          this.props.handleClose();

        }}
      >
        {(close) => (
          <div className="fade_1_1">
            <div className="close_wrap">
              <div className="close_box">
                <a
                  href="#"
                  className="fade_1_1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="/web/images/close.png" />
                </a>
              </div>
            </div>
            <div className="popup_wrap">
              <div className="popup_box">
                <div className="popup_start">
                  <div className="popup">
                    <div className="title_wrap">
                      <div className="title">
                        마이페이지 <span className="title2">MY PAGE</span>{" "}
                        <span>
                          <img src="/web/images/logo.png" width="120" />
                        </span>
                      </div>
                    </div>
                    {/* <div className="con_box10">
                      <div className="tab_wrap">
                        <ul>
                          <li>
                            <a href="#">
                              <span className="tabon">입금신청</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span className="tab">출금신청</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}

                    <div className="con_box10">
                      <div className="tab_wrap">
                        <ul>
                          <li>
                            <a
                              className="fade_5_1_close fade_5_1_open"
                              onClick={() => {
                                this.setState({ mode: "exchange" });
                              }}
                            >
                              <span
                                className={
                                  this.state.mode === "exchange"
                                    ? "tabon"
                                    : "tab"
                                }
                              >
                                회원정보
                              </span>
                            </a>
                          </li>
                          {/* <li>
                            <a
                              className="fade_5_1_close fade_5_2_open"
                              onClick={() => {
                                this.setState({ mode: "rolling" });
                                this.handleGetUserRolling();
                              }}
                            >
                              <span
                                className={
                                  this.state.mode === "rolling"
                                    ? "tabon"
                                    : "tab"
                                }
                              >
                                롤링 포인트
                              </span>
                            </a>
                          </li> */}
                          <li>
                            <a
                              className="fade_5_1_close fade_5_4_open"
                              onClick={() => {
                                this.setState({ mode: "note" });
                                this.handleNotice();
                              }}
                            >
                              <span
                                className={
                                  this.state.mode === "note" ? "tabon" : "tab"
                                }
                              >
                                쪽지함
                              </span>
                            </a>
                          </li>
                          <li>
                            <a
                              className="fade_5_1_close fade_5_5_open"
                              onClick={() => {
                                this.setState({ mode: "balance" });
                                this.handleBalance(1);
                              }}
                            >
                              <span
                                className={
                                  this.state.mode === "balance"
                                    ? "tabon"
                                    : "tab"
                                }
                              >
                                입금출 기록
                              </span>
                            </a>
                          </li>
                          {/* <li>
                            <a
                              href="#"
                              className="fade_5_1_close fade_5_6_open"
                            >
                              <span className="tab">베팅내역</span>
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </div>

                    {RenderExchangePass()}
                    {/* {RenderRolling()} */}
                    {RenderBalance()}
                    {RenderNote()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}
