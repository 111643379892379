import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import

import { BalanceService } from "../../service/balance.service";
import { UserService } from "../../service/user.service";

import {
    ConverMoeny,
  } from "../../utility/help";

interface Props {
    user : any
    handleClose: () => any;
}

interface State {
    user : any
    rolling : number
}

export class Comp extends Component<Props, State> {
  balanceService = new BalanceService();
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
        user : {},
        rolling : 0,
    };
  }

  

  handleRollingToBalance = () => {
  
    this.balanceService.rolingToBalance(this.state.rolling).then((data) => {
      if (data.status === "success") {
        confirmAlert({
          title: "콤프 전화",
          message: "콤프 전화을 성공하였습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                this.props.handleClose();
              },
            },
          ],
        });
        return;
      } else if (data.status === "pass") {
        confirmAlert({
          title: "콤프 전화",
          message: "환전 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
        return;
      } else {
        confirmAlert({
          title: "콤프",
          message:
            "알수없는 예러가 발상하였습니다 문제가 지속된다면 관리자에게 문의 바람니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };


  componentDidMount() {
    this.updateUser();
  }

  updateUser = () => {
    this.userService.healthCheck().then((s: any) => {
      if (s.status === "success") {
        let user = {
          id: s.user.id,
          balance: s.user.balance,
          rolling: s.user.rolling,
          point: s.user.point,
        };

        if (JSON.stringify(user) !== JSON.stringify(this.state.user)) {
          this.setState({ user: user });
        }
      } else {
        // window.location.hash = "/";
      }
    });
  };

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        open={true}
        contentStyle={{
          zIndex: 99999,
          background: "none",
          border: "none",
          width: "none",
        }}
        onClose={() => {}}
      >
        {(close) => (
          <div className="fade_1_1">
            <div className="close_wrap">
              <div className="close_box">
                <a
                  href="#"
                  className="fade_1_1_close"
                  onClick={() => {
                    this.props.handleClose();
                  }}
                >
                  <img src="/web/images/close.png" />
                </a>
              </div>
            </div>
            <div className="popup_wrap">
              <div className="popup_box">
                <div className="popup_start">
                  <div className="popup">
                    <div className="title_wrap">
                      <div className="title">
                        콤프 <span className="title2"></span>
                        <span>
                          <img src="/web/images/logo.png" width="120" />
                        </span>
                      </div>
                    </div>
                    {/* <div className="con_box10">
                      <div className="tab_wrap">
                        <ul>
                          <li>
                            <a href="#">
                              <span className="tabon">입금신청</span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <span className="tab">출금신청</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    <div className="con_box10">
                      <div className="info_wrap">
                        <div className="info2">
                        콤프 보유금 
                            <span className="font06"> {"   " + ConverMoeny(this.props.user?.rolling ?? 0)}
                            </span>
                        </div>
                        {/* <div className="info3">
                          <span className="font06">
                            최소 입금액은 3만원이상, 만원단위
                          </span>
                          로 신청가능, 입금전 반드시 계좌문의 하시기바랍니다.
                          <br />
                        </div> */}
                      </div>
                    </div>
                    <div className="con_box10">
                      <table className="write_title_top">
                        {/* <tr>
                          <td className="write_title">충전요청게임</td>
                          <td className="write_basic">
                            <select className="input1"></select>
                          </td>
                        </tr> */}
                        <tr>
                          <td className="write_title">콤프</td>
                          <td className="write_basic">
                            <input
                              className="input1"
                              value={this.state.rolling}
                              onChange={(e) =>
                                this.setState({
                                    rolling: Number(e.target.value),
                                })
                              }
                            />
                            <a onClick={()=> this.handleRollingToBalance()}>
                              <span className="btn1_1">전환 하기</span>
                            </a>
                            
                          </td>
                        </tr>


                      </table>
                    </div>
                    <div className="con_box10">
                      <div className="info_wrap">
                        <div className="info3">
                          23:50 ~ 00:30, 휴일 다음 첫 영업일 새벽에는
                          은행점검으로 인해 계좌이체가 지연될 수 있습니다.
                          <br />위 시간 이외에도 몇몇 은행은 추가적 점검시간이
                          따로 있으니 이점 유념하시기 바랍니다.
                        </div>
                      </div>
                    </div>
            
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Popup>
    );
  }
}
