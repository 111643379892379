import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
// import { Helmet } from "react-helmet";

interface Props {
  user: any;
}

interface State {}

function Live(name: any) {}

export class Footer extends Component<Props, State> {
  contents: any = "";
  instance: any = null;
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    let name = "손님";
    if (
      this.props.user != null &&
      this.props.user.id !== null &&
      this.props.user.id !== ""
    ) {
      name = this.props.user.id;
    }
    return (
      <div className="footer_wrap" ref={(el) => (this.instance = el)}>
        <img src="/web/images/footer.png" />
        {Live("kkkk")}
      </div>
    );
  }
}
