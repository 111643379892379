import React, { Component } from "react";

import styled from "styled-components";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
// import Iframe from "react-iframe";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Popup from "reactjs-popup";

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";

import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";

import { BaseCSSProperties } from "@material-ui/core/styles/withStyles";

import { UserService } from "../../service/user.service";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { TopBar } from "../share/topBar";
import { Footer } from "../share/footer";
import { BetBtn } from "../share/bet-btn";
import { MiniService } from "../../service/mini.service";
const drawerWidth = 240;

const NewExpansionPanel = styled(ExpansionPanel)({
  borderBottom: "1px solid #9E9E9E",
});

const NewExpansionPanelSummary = styled(ExpansionPanelSummary)({
  border: 0,
  borderRadius: 3,
  color: "white",
  padding: "none",
});

interface Props {
  user: any;
}

interface State {
  ID: string;
  PW: string;
  helps: any;
  isView: boolean;

  title: string;
  contents: string;
}
export class Help extends Component<Props, State> {
  userService = new UserService();

  constructor(props: Props) {
    super(props);
    this.state = {
      ID: "",
      PW: "",
      helps: [],
      isView: true,
      title: "",
      contents: "",
    };
  }

  componentDidMount() {
    this.handleGetNotices();
  }
  handleGetNotices = () => {
    this.userService.get_help_list().then((data: any) => {
      if (data.status === "success") {
        this.setState({ helps: data.helps });
      }
    });
  };

  handleSaveHelp = (title: string, contents: string) => {
    this.userService.user_wirte_help(title, contents).then((date: any) => {
      if (date.status === "success") {
        confirmAlert({
          title: "고객센터",
          message: "게시물이 등록되었습니다.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
                window.location.hash = "/help";
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "고객센터",
          message: "로그인이후에 사용해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {},
            },
          ],
        });
      }
    });
  };

  render() {
    let helps = this.state.helps;

    return (
      <React.Fragment>
        {/* <div className="header_wrap">
          <TopBar user={this.props.user}></TopBar>
        </div> */}
        <div id="title_wrap">
          <img src="/mo/images/gnb5over.png" />
        </div>
        <div className="contents_wrap">
          <div className="contents_box">
            {this.state.isView ? (
              <div className="con_box00">
                {helps.map((data: any) => (
                  <NewExpansionPanel>
                    <NewExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography>
                        <div className="m_list_notice">
                          {data.title}
                          <span className="m_list2">
                            {ConvertDate(data.regDate)} |{" "}
                            {data.status === "already" ? "완료" : "대기"}
                          </span>
                        </div>
                      </Typography>
                    </NewExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography>
                        <div
                          dangerouslySetInnerHTML={{ __html: data.contents }}
                        ></div>
                      </Typography>
                    </ExpansionPanelDetails>
                    {data.ref != null &&
                    data.ref.contents != null &&
                    data.status === "already" ? (
                      <div>
                        <ExpansionPanelDetails>
                          <Typography color="secondary">답변 : </Typography>
                        </ExpansionPanelDetails>
                        <ExpansionPanelDetails>
                          <br />
                          <Typography>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data.ref.contents,
                              }}
                            ></div>
                          </Typography>
                        </ExpansionPanelDetails>
                      </div>
                    ) : (
                      ""
                    )}
                  </NewExpansionPanel>
                ))}

                <div className="con_box05">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td>
                          <a
                            href="#"
                            onClick={() => {
                              this.setState({ isView: false });
                            }}
                          >
                            <span className="m_btn3">글쓰기</span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="con_box00">
                <table className="write_title_top" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="write_title">제목</td>
                      <td className="write_basic">
                        <input
                          className="input1"
                          onChange={(e) =>
                            this.setState({
                              title: e.target.value,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="write_title">내용</td>
                      <td className="write_basic">
                        <textarea
                          className="input1"
                          style={{ height: "200px" }}
                          onChange={(e) =>
                            this.setState({
                              contents: e.target.value,
                            })
                          }
                        ></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="con_box05">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td>
                          <a>
                            <span
                              className="m_btn3"
                              onClick={() => {
                                this.handleSaveHelp(
                                  this.state.title,
                                  this.state.contents
                                );
                                this.setState({ isView: false });
                              }}
                            >
                              확인
                            </span>
                          </a>
                        </td>
                        <td>
                          <a>
                            <span
                              className="m_btn3"
                              onClick={() => {
                                this.setState({ isView: true });
                              }}
                            >
                              취소
                            </span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
