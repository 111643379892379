import React, { Component } from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import CopyrightIcon from "@material-ui/icons/Copyright";
// const drawerWidth = 440;
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SlotService } from "../../service/slot.service";
import { UserService } from "../../service/user.service";

import {
  ConvertDate,
  ConverMoeny,
  ConvertBalanceStateToText,
} from "../../utility/help";

const CustomButton = styled(Button)`
  /* background-color: #6772e5; */
  /* color: #000;
  line-height: normal;
  font-size: 12px;
  font: bold; */

  box-shadow: inset 0px 1px 0px 0px #cf866c;
  background: linear-gradient(to bottom, #d0451b 5%, #bc3315 100%);
  background-color: #d0451b;
  border-radius: 3px;
  border: 1px solid #942911;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 12px;
  /* padding: 6px 24px; */
  text-decoration: none;
  text-shadow: 0px 1px 0px #854629;
`;

const styles = (theme: any) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
  },

  //   drawer: {
  //     width: drawerWidth,
  //     flexShrink: 0,
  //   },
  //   drawerPaper: {
  //     width: drawerWidth,
  //   },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    alignSelf: "flex-end",
    display: "inline",
    padding: "10px",
    fontSize: "16px",
  },
  investing: {
    fontSize: "18px",
  },
});

const CustomToolbar = styled(Toolbar)`
  min-height: 0px;
  /* .muitoolbar-regular : {
  } */
`;

interface Props {
  classes: any;
  user: any;
  tryLoginOut: () => any;
  //   session: any;
}

interface State {
  balance: number;
  isBalanceupdate: boolean;
  postCount: number;
  note: any;
  isOpen: boolean;
  isOpenWindow: boolean;
}
class topBar extends Component<Props, State> {
  static propTypes: { classes: PropTypes.Validator<object> };
  userService = new UserService();
  slotService = new SlotService();

  constructor(prop: Props) {
    super(prop);
    this.state = {
      balance: 0,
      isBalanceupdate: false,
      postCount: 0,
      note: [],
      isOpen: false,
      isOpenWindow: false,
    };
  }

  componentDidMount() {
    if (this.props.user != null) {
      this.setState({ balance: this.props.user.balance });
    }
    setInterval(() => {
      this.handleUpdateNote();
    }, 30000);
    this.handleUpdateNote();
    this.handleUpdateInBalance();
  }

  handleUpdateNote = () => {
    this.userService.get_user_note().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          postCount: data.count,
          note: data.note,
          isOpen: data.count > 0 ? true : false,
        });

        if(data.count > 0){
          window.location.hash = "note";
          // this.setState({popupStatuses: popupView.user});
        }
      }
      //else if (this.props.authenticated) {
      //   this.props.tryLoginOut();
      // }
    });
  };

  handleUpdateInBalance = () => {
    this.setState({ isBalanceupdate: true });

    this.slotService.get_in_game_balance().then((data: any) => {
      if (data.status === "success") {
        this.setState({
          balance: data.balance,
          isBalanceupdate: false,
        });
      } else {
      }
    });
  };

  render() {
    const classes = this.props.classes;

    const RenderLogin = () => {
      if (
        this.props.user != null &&
        this.props.user.id !== null &&
        this.props.user.id !== ""
      ) {
        return (
          <div className="m_menu">
            <table>
              <tr key={"user"}>
                <td align="center" style={{ color: "#edddd8" }}>
                  {this.props.user.id}{" "}
                  <span className="font05">
                    {ConverMoeny(this.state.balance)}
                  </span>
                  원{" "}
                  {this.state.isBalanceupdate ? (
                    <CircularProgress disableShrink size={20} />
                  ) : (
                    <RefreshIcon
                      onClick={() => {
                        this.handleUpdateInBalance();
                      }}
                    ></RefreshIcon>
                  )}
                  <br />
                </td>
              </tr>
            </table>
          </div>
        );
      }

      return (
        <>
          <a
            onClick={() => {
              window.location.hash = "/login";
            }}
          >
            <img src="/mo/images/m_login_login2.jpg" width="70" />
          </a>
          <a
            onClick={() => {
              window.location.hash = "/reg";
            }}
          >
            <img src="/mo/images/m_login_join2.jpg" width="70" />
          </a>
        </>
      );
    };

    let isOpenWindow = this.state.isOpenWindow;

    return (
      <>
        <div className="top">
          <div className="m_logo">
            <a
              onClick={() => {
                window.location.hash = "";
              }}
            >
              <img src="/mo/images/m_logo.png" width="60" />
            </a>

            {RenderLogin()}
          </div>
          <div className="m_menu">
            <span
              className="open"
              onClick={() => {
                this.setState({ isOpenWindow: true });
              }}
            >
              <img src="/mo/images/m_menu.png" width="60" />
            </span>
          </div>
        </div>

        <div className="notice">
          <img src="/mo/images/notice_title.png" width="55" />
          입금 계좌는 <span className="font05">수시로</span> 변경되오니 입금전 꼭 확인바랍니다.

        </div>

        <div
          className="quick_wrap"
          style={{ right: isOpenWindow ? "0px" : "-360px" }}
        >
          <div className="sidebar1">
            <div className="">
              <a
                onClick={() => {
                  this.setState({ isOpenWindow: false });
                }}
              >
                <span className="close">
                  <img src="/mo/images/m_close.png" width="45" />
                </span>
              </a>
            </div>

            {this.props.user != null &&
            this.props.user.id !== null &&
            this.props.user.id !== "" ? (
              <div className="cart_my">
                <table>
                  <tr key={"user_level"}>
                    <td align="center" style={{ color: "#edddd8" }}>
                      레벨{" "}
                      <span className="font05">{this.props.user.level}</span>LV
                      &nbsp;&nbsp;&nbsp;{" "}
                      <span className="font01">{this.props.user.id}</span>
                      환영합니다.
                    </td>
                  </tr>
                  <tr key="slot_balance">
                    <td align="center" style={{ color: "#edddd8" }}>
                      머니{" "}
                      <span className="font05">
                        {ConverMoeny(this.state.balance)}
                      </span>
                      원
                    </td>
                  </tr>
                  <tr key="bacara_balance">
                    <td align="center" style={{ color: "#edddd8" }}>
                      포인트{" "}
                      <span className="font05">
                        {ConverMoeny(this.props.user.point)}
                      </span>
                      원
                    </td>
                  </tr>
                  <tr key="notice_count">
                    <td align="center" style={{ color: "#edddd8" }}>
                      쪽지 <span className="font05">({ConverMoeny(this.state.postCount)})</span>
                    </td>
                  </tr>
                </table>
              </div>
            ) : (
              <></>
            )}

            <div className="con_box00">
              <table style={{ width: "100%" }}>
                <tr>
                  <td align="center" key="deposit_count">
                    <a
                      onClick={() => {
                        window.location.hash = "deposit";
                        this.setState({ isOpenWindow: false });
                      }}
                    >
                      <span className="m_btn5">입금신청</span>
                    </a>
                  </td>
                  <td align="center" key="withdraw_count">
                    <a
                      onClick={() => {
                        window.location.hash = "withdraw";
                        this.setState({ isOpenWindow: false });
                      }}
                    >
                      <span className="m_btn5">출금신청</span>
                    </a>
                  </td>
                </tr>
                {/* <tr>
                  <td align="center">
                    <a href="sub03.html">
                      <span className="m_btn5">쿠폰함</span>
                    </a>
                  </td>
                  <td align="center">
                    <a href="sub04.html">
                      <span className="m_btn5">이벤트신청</span>
                    </a>
                  </td>
                </tr> */}
                <tr key="mypage_count">
                  <td align="center" key="mypage_view">
                    <a
                      onClick={() => {
                        window.location.hash = "mypage";
                        this.setState({ isOpenWindow: false });
                      }}
                    >
                      <span className="m_btn5">마이페이지</span>
                    </a>
                  </td>
                  <td align="center" key="mypage_note">
                    <a
                      onClick={() => {
                        window.location.hash = "notice";
                        this.setState({ isOpenWindow: false });
                      }}
                    >
                      <span className="m_btn5">공지사항</span>
                    </a>
                  </td>
                </tr>

                <tr key="mypage_logout">
                  <td align="center" key="mypage_note">
                    <a
                      onClick={() => {
                        window.location.hash = "help";
                        this.setState({ isOpenWindow: false });
                      }}
                    >
                      <span className="m_btn5">고객센터</span>
                    </a>
                  </td>
                  <td align="center" key="mypage_note">
                    <a
                      onClick={() => {
                        window.location.hash = "point";
                        this.setState({ isOpenWindow: false });
                      }}
                    >
                      <span className="m_btn5">금고</span>
                    </a>
                  </td>
                </tr>
                <tr key="mypage_logout">

                  <td align="center" key="mypage_note">
                    <a
                      onClick={() => {
                        window.location.hash = "rolling";
                        this.setState({ isOpenWindow: false });
                      }}
                    >
                      <span className="m_btn5">콤프</span>
                    </a>
                  </td>
                  <td>
                    <a
                      onClick={() => {
                        this.props.tryLoginOut();
                        window.location.reload();
                      }}
                    >
                      <span className="m_btn4">로그아웃</span>
                    </a>
                  </td>
                </tr>
              </table>
            </div>
            <div className="con_box50" style={{ height: "500px" }}></div>
          </div>
        </div>
      </>
    );
  }
}

topBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export let TopBar = withStyles(styles, { withTheme: true })(topBar);
export default TopBar;
